@import url(https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800,900);
* {
  margin: 0;
  padding: 0;
}

.barColourChange {
  transition: fill 0.5s;
}

html,
body {
  font-size: clamp(12px, 1.12vw, 36px);
}

